<template>
    <fragment>
        <li v-if="showAddMouseCoordinatesAsEventDestination" @click="start()">
            <v-icon left color="red">ter-destination-full</v-icon>
            <span>Nastavit jako místo dojezdu techniky</span>
        </li>
        <li v-if="showUpdateEventDestination" @click="start()">
            <v-icon left color="red">ter-destination-full</v-icon>
            <span>Změnit místo dojezdu techniky</span>
        </li>
    </fragment>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: 'event-destination-context-menu',
        props: {
            data: {
                type: Object,
                required: true,
            }
        },
        computed: {
            ...mapGetters('events', {
                isActiveEvent: 'isActiveEvent',
                event: 'getActiveEvent',
            }),
            ...mapGetters('eventCategories', {
                eventCategoryById: 'getEventCategoryById',
            }),
            eventCategory() {
                return this.eventCategoryById(this.event.eventCategoryId);
            },
            // getEventCategoryById
            showAddMouseCoordinatesAsEventDestination() {
                return this.isActiveEvent && this.data.area === 'map' && !this.data.feature && this.eventCategory.type === 'event';
            },
            showUpdateEventDestination() {
                return this.isActiveEvent && this.data.area === 'map' && this.data.feature && this.data.type === 'eventDestination' && this.eventCategory.type === 'event' ;
            },
        },
        methods: {
            start() {
                this.$store.dispatch('eventDestination/start', this.data.coordinates);
            },
        },
    }
</script>
